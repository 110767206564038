import React, { useRef, useState } from "react";

export const SolParse = () => {
    const canvasRef = useRef(null);
    const [blocks, setBlocks] = useState([]); // State to hold blocks

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const jsonData = JSON.parse(e.target.result);
                setBlocks(jsonData.blocks); // Store blocks in state
                drawConnections(jsonData.blocks); // Draw blocks
            };
            reader.readAsText(file);
        }
    };

    const drawConnections = (blocks) => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

        // Draw blocks
        blocks.forEach(block => {
            // Draw block
            ctx.fillStyle = 'lightblue';
            ctx.fillRect(block.x, block.y, 100, 50); // Example dimensions
            ctx.strokeRect(block.x, block.y, 100, 50);
            ctx.fillStyle = 'black';
            ctx.fillText(block.name, block.x + 10, block.y + 25); // Example text
        });
    };

    // Define handleCanvasClick after drawConnections
    const handleCanvasClick = (event) => {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        // Check if a block was clicked
        blocks.forEach(block => {
            if (x >= block.x && x <= block.x + 100 && y >= block.y && y <= block.y + 50) {
                alert(`Clicked on ${block.name}`); // Replace with your desired action
            }
        });
    };

    return (
        <div>
            <input type="file" className="inp-solution" onChange={handleFileChange} />
            <canvas 
                ref={canvasRef} 
                width={800} 
                height={600} 
                style={{ border: '1px solid black'}} 
                onClick={handleCanvasClick}
            />
        </div>
    )
}