import { useEffect, useState } from "react";
import { Button } from "./UI/Button";
import { Backlog } from "./Backlog";

const DOMAIN = "https://alexandr-bashorin.com";

export const MainPage = () => {
    const [currentWeekOffset, setCurWeekOffset] = useState(0);
    const [isVisModalBacklog, toggleIsVisModalBacklog] = useState(false);
    const [weekdays, setWeekdays] = useState([]);
    const [curweek, setCurWeek] = useState("");
    const [users, setUsers] = useState([]);

    const plus_backlog = () => {
        toggleIsVisModalBacklog(!isVisModalBacklog);
    };

    const format_date = (date) => {
        return date.toLocaleDateString('ru-RU', {
            weekday: 'short',
            day: 'numeric',
            month: 'short'
        });
    };
    const parse_date_string = (date_string) => {
        const [day, month, year] = date_string.split(".").map(Number);
        return new Date(year, month - 1, day);
    }

    const get_dates = () => {
        const today = new Date();
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() + (currentWeekOffset * 7));
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);

        const dates = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(startOfWeek);
            date.setDate(startOfWeek.getDate() + i);
            dates.push(date);
        }

        const formatDate = (date) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            return `${day}.${month}`;
        };

        setCurWeek(formatDate(dates[0]) + " - " + formatDate(dates[dates.length - 1]));
        return dates;
    };

    useEffect(() => {
        setWeekdays(get_dates());
    }, [currentWeekOffset]);

    async function change_week(increment) {
        setCurWeekOffset(currentWeekOffset + increment);
        setWeekdays(get_dates());
        await fetchUsers();
    }

    async function fetchUsers() {
        let resp = await fetch(DOMAIN + "/api/get_users");
        if (resp.ok) {
            let data = await resp.json();
            setUsers(data);
        } else {
            setUsers([]);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);
    console.log(users)

    // DND
    const handleDragStart = (e, task) => {
        e.dataTransfer.setData('task', JSON.stringify(task)); // Store the task data
    };
    const handleDrop = (e, user) => {
        e.preventDefault()
        const taskData = e.dataTransfer.getData("task")
        const task = JSON.parse(taskData)

        const taskExists = user.tasks.some((userTask => userTask.startDate == task.startDate))
        if (taskExists) {
            assignTaskToUser(user.id, task);
        }

    }
    const handleDragOver = (e) => {
        e.preventDefault(); // Prevent default to allow drop
    };

    function assignTaskToUser(user_id, task) {
        let user_row = document.querySelector(".row-user[data-id='" + user_id + "']")
        if (user_row) {
            let task_column = user_row.querySelector(".task-column[data-startDate='" + task.startDate + "']")
            if (task_column) {
                let taskItem = document.createElement("div")
                taskItem.classList.add("row-user__task-item")
                taskItem.dataset.id = task.id
                taskItem.tooltip = task.description
                taskItem.textContent = task.title
                task_column.appendChild(taskItem)
            }
        }
    }

    return (
        <div className="schedule">
            <div className="schedule__tasktracker">
                <div className="schedule__header">
                    <h2>Schedule of tasks</h2>
                    <div className="schedule__header-navigation">
                        <Button class_name={"schedule__header-prev-week btn"}
                            name_of_button={"prev week"} action_of_button={() => change_week(-1)} />
                        <div className="cur-week">{curweek}</div>
                        <Button class_name={"schedule__header-next-week btn"}
                            name_of_button={"next week"} action_of_button={() => change_week(1)} />
                    </div>
                </div>
                <div className="schedule__dates">
                    <div className="task-column" key="empty-column"></div> {/* Empty column for user names */}
                    {
                        weekdays.map((e, ind) => (
                            <div className="task-column" key={ind} data-colindex={ind} data-id={format_date(e)}>
                                <div className="date-item" data-id={ind}>{format_date(e)}</div>
                            </div>
                        ))
                    }
                </div>
                <div className="schedule__userlines">
                    {
                        users && users.length > 0 ?
                            (users.map((user) => (
                                <div
                                    className="row-user"
                                    data-id={user.id}
                                    key={user.id}
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, user)}
                                >
                                    <div className="row-user__user-item">{user.name}</div>
                                    {weekdays.map((date, index) => {
                                        if (user.tasks && user.tasks.length) {
                                            const tasksForDate = user.tasks.filter(task => {
                                                const taskDate = parse_date_string(task.startDate);
                                                return format_date(taskDate) === format_date(date);
                                            });

                                            return (
                                                tasksForDate.map(task => (
                                                    <div className="task-column" data-startDate={task.startDate} key={index}>
                                                        <div
                                                            key={task.id}
                                                            className="row-user__task-item"
                                                            data-id={task.id}
                                                            data-tooltip={task.description}
                                                        >
                                                            {task.title}
                                                        </div>
                                                    </div>
                                                ))
                                            );
                                        }
                                    })
                                    }
                                </div>
                            )))
                            : null
                    }
                </div>
            </div>
            <div className="schedule__backlog">
                <h2>Backlog</h2>
                <Button
                    class_name={"open-form-new-backlog-task btn"}
                    name_of_button={"+backlog"}
                    action_of_button={plus_backlog}
                />
                <Backlog
                    isVisible={isVisModalBacklog}
                    onDragStart={handleDragStart}
                />
            </div>
        </div>
    );
};




