import './App.css';
import React from 'react';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import { Header } from './components/Header';
import {MainPage} from './components/MainPage';
import { Alexbash } from './components/AlexBash';
import { SolParse } from './components/SolParse';
import { Articles } from './components/Articles';

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/projects" element={<MainPage />} />
          <Route path="/solparse" element={<SolParse />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/" element={<Alexbash />} />
        </Routes>
      </main>
    </Router>
  );
}

export default App;
