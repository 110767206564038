import { useEffect, useState } from "react"
import { Button } from "./UI/Button"

const DOMAIN = "https://alexandr-bashorin.com"

export const Backlog = (props) => {
    const miniID = () => {
        let firstPart = (Math.random() * 46656) | 0;
        let secondPart = (Math.random() * 46656) | 0;
        firstPart = ("000" + firstPart.toString(36)).slice(-3);
        secondPart = ("000" + secondPart.toString(36)).slice(-3);
        return firstPart + secondPart;
    };

    const [currentBacklog, setCurrentBacklog] = useState([]);
    const [newBacklog, setNewBacklog] = useState({
        "id": miniID(),
        "title": "",
        "description": "",
        "startDate": "",
        "endDate": ""
    });
    const [errWrite, setErrWrite] = useState(false);
    const [errWriteMessage, setErrWRiteMessage] = useState("");
    const [dataWrited, setDataWRited] = useState(false);

    const inputs_plus_backlog = [
        { lable: "Title", name: "title", type: "text", class: "form-new-backlog-task__name" },
        { lable: "Description", name: "description", type: "text", class: "form-new-backlog-task__descript" },
        { lable: "Start", name: "startDate", type: "date", class: "form-new-backlog-task__start" },
        { lable: "End", name: "endDate", type: "date", class: "form-new-backlog-task__end" },
    ]

    // get all current tasks
    async function getCurrentBacklog() {
        try {
            let response = await fetch(DOMAIN + "/api/get_current_backlog")
            if (!response.ok) {
                console.error("Can't get current backlog: " + response.statusText)
                return
            }
            let tasks_without_user = await response.json()
            setCurrentBacklog(tasks_without_user)
        } catch (e) {
            console.error("Can't get current backlog: " + e.message)
        }
    }
    useEffect(() => {
        getCurrentBacklog()
    }, [])
    console.log("current backlog: " + JSON.stringify(currentBacklog))

    // function for handle every inputs of new task form
    const fillNewBacklog = (which, new_value) => {
        if (newBacklog) {
            console.log("has")
            let currentNewBacklog = Object.assign(newBacklog)
            currentNewBacklog[which] = new_value
            setNewBacklog(currentNewBacklog)
        }
        console.log(newBacklog)
    }

    // alert message about new task
    const alertDataWrited = () => {
        setDataWRited(true)
        setTimeout(() => {
            setDataWRited(false)
        }, 5000)
    }

    // submit new task
    const submitaction = async () => {
        try {
            let response = await fetch("/api/create_backlog", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(newBacklog)
            })

            if (!response.ok) {
                setErrWrite(true)
                setErrWRiteMessage("Ошибка Записи: " + await response.text())
            } else {
                setErrWrite(false)
                setErrWRiteMessage("")
                alertDataWrited()

                let writed_backlog = await response.json()
                let get_current_backlog = Object.assign(currentBacklog)
                get_current_backlog.push(writed_backlog)
                setCurrentBacklog(get_current_backlog)

                document.querySelector(".form-new-backlog-task").querySelectorAll("input").forEach(e => {
                    e.value = ""
                })
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="form-new-backlog-task">
            {
                props.isVisible ? (
                    <div>
                        {inputs_plus_backlog && inputs_plus_backlog.length > 0 ? (
                            inputs_plus_backlog.map((el, ind) => (
                                <div key={ind} className="modal-label-container">
                                    <label htmlFor={el.name}>{el.lable}</label>
                                    <input
                                        className={el.class}
                                        name={el.name}
                                        type={el.type}
                                        onChange={(e) => fillNewBacklog(el.name, e.target.value)}
                                    />
                                </div>
                            ))
                        ) : null}
                        <Button
                            class_name={"create-new-backlog-task btn"}
                            name_of_button={"Create backlog"}
                            action_of_button={submitaction}
                        />
                        {errWrite ? (
                            <>
                                <br />
                                <b style={{ color: "red" }}>{errWriteMessage}</b>
                            </>
                        ) : null}
                        {dataWrited ? (
                            <b>New task recorded</b>
                        ) : null}
                    </div>
                ) : null
            }

            {
                currentBacklog && currentBacklog.length > 0 ?
                    <div className="curren-backlog">
                        {
                            currentBacklog.map((task, i) => (
                                <div
                                    className="curren-backlog__item"
                                    key={task.id}
                                    draggable={true}
                                    onDragStart={(e) => props.handleDragStart(e, task)}
                                >
                                    {task.title} | {task.startDate}
                                </div>
                            ))
                        }
                    </div>
                    : null
            }
        </div>
    )
}
