import { useEffect, useState } from "react"

export const Articles = () => {
    const DOMAIN = "https://alexandr-bashorin.com"

    const [articles, setArticles] = useState([]);
    const [is_content_editble, setIsContentEditble] = useState(false);

    async function fetchArticles() {
        let request = await fetch(DOMAIN + "/api/articles")
        if(request.ok) {
            let data = await request.json()
            setArticles(data)
        } else {
            console.error(request.status + request.statusText)
            setArticles([])
        }
    }
    useEffect(() => {
        fetchArticles()
    },[])
    console.log("articles: " + JSON.stringify(articles))

    return (
        <div className="articles">
            <div>
                <input placeholder="Search" className="search-articles" />
                <button>+ Article</button>
            </div>
            {
                articles && articles.length > 0 ?
                    articles.map((e, i) => (
                        <div key={e.id} className="articles__item">
                            {e.title}
                            <textarea contentEditable={is_content_editble}>
                                {e.content}
                            </textarea>
                        </div>
                    ))
                : null
            }
        </div>
    )
}