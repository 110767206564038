import { useState } from "react";
import { Button } from "./Button";

export const Modal_small = (props) => {
    const [submit_login, setSubmitLogin] = useState({});
    const [submit_password, setSubmitPassword] = useState({});

    // submit modal
    const submit_action = async() => {
        try {
            const response = await fetch("/api/login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: submit_login,
                    password: submit_password
                })
            })
            console.log(response)
        
            if(response.ok) {
                const data = await response.text()
                window.localStorage.setItem('token', data.token)
            }
        } catch(e) {
            console.error(e)
        }
    }

    return (
        props.isVisible ?
        <div className="modal-small">
            {props.inputs && props.inputs.length > 0 ? 
            props.inputs.map((inp, index) => (
                <input key={index}
                    type={inp.type}
                    placeholder={inp.placeholder}
                    className={inp.class}
                    onChange={(e) => {
                        if(inp.type == "username") {
                            setSubmitLogin({wich: inp.type, value: e.target.value})
                        }
                        if(inp.type == "userpassword") {
                            setSubmitPassword({wich: inp.type, value: e.target.value})
                        }
                    }}
                />
            ))
            : 
            null}
            <Button class_name={props.act_btn_class} action_of_button={submit_action} name_of_button={"Log in"} />
        </div>
        : null
    )
}