import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import { Button } from './UI/Button';
import { useState } from 'react';
import { Modal_small } from './UI/Modal_small';

export const Header = () => {
    const [form_is_open, setFormIsOpen] = useState(false);

    let login_inputs = [
        {type: "username", placeholder: "enter your name", class: "username"},
        {type: "userpassword", placeholder: "enter your password", class: "userpassword"},
    ];
    const action_login = () => {
        console.log("start login")
        setFormIsOpen(!form_is_open)
    }

    return (
        <header className="haeder">
            <Link to="/" className="logo">
                <b>AlexBash</b>
            </Link>
            <nav>
                <Link to="/projects">Projects</Link>
                <Link to="/articles">Articles</Link>
                <Link to="/solparse">Parse solutions</Link>
            </nav>
            <Button name_of_button={"login"} action_of_button={action_login} class_name={"login-btn btn"} />
            <Modal_small isVisible={form_is_open} 
                inputs={login_inputs}
                act_btn_class={"login-btn"}
            />
        </header>
    )
}