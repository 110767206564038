import { Articles } from "./Articles"

export const Alexbash = () => {
    return (
        <div className="main-page">
            <div className="rule">Every problem has a solution</div>
            <div className="main-posts">
                <Articles />
            </div>
        </div>
    )
}